// src/authProvider.js

const authProvider = {
  login: ({ username, password }) => {

    const envUsername = process.env.REACT_APP_ADMIN_USERNAME;
    const envPassword = process.env.REACT_APP_ADMIN_PASSWORD;

    if (username === envUsername && password === envPassword) {
      // 예시로 간단한 로그인 성공 시 처리
      localStorage.setItem('auth', JSON.stringify({ username }));
      return Promise.resolve();
    }
    return Promise.reject(new Error('Login error'));
  },
  logout: () => {
    // 로그아웃 로직 구현
    localStorage.removeItem('auth');
    return Promise.resolve();
  },
  checkError: (error) => {
    // 오류 처리 로직 구현
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('auth');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    // 인증 상태 확인 로직 구현
    return localStorage.getItem('auth') ? Promise.resolve() : Promise.reject();
  },
  getPermissions: () => {
    // 권한 가져오기 로직 구현
    return Promise.resolve();
  },
};

export default authProvider;
