// src/components/PushNotificationRedirect.jsx

import React, { useEffect } from 'react';
import { useRedirect } from 'react-admin';

const PushNotificationRedirect = () => {
  const redirect = useRedirect();

  useEffect(() => {
    redirect('/push-notifications/create');
  }, [redirect]);

  return null;
};

export default PushNotificationRedirect;
