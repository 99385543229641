// src/App.js

import React from 'react';
import { Admin, Resource } from 'react-admin';
import dataProvider from './dataProvider';
import authProvider from './authProvider';
import UserList from './components/UserList';
import PushNotificationSend from './components/PushNotificationSend'; // 파일 이름 변경
import PushNotificationRedirect from './components/PushNotificationRedirect'; // 리다이렉트 컴포넌트 추가
import Dashboard from './components/Dashboard';

const App = () => (
  <Admin
    dashboard={Dashboard}
    dataProvider={dataProvider}
    authProvider={authProvider}
  >
    <Resource name="users" list={UserList} />
    <Resource
      name="push-notifications"
      list={PushNotificationRedirect} // 리다이렉트 컴포넌트 추가
      create={PushNotificationSend}
      options={{ label: '푸시 알림' }}
    />
  </Admin>
);

export default App;
