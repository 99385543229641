// src/components/UserList.js

import React from 'react';
import { List, Datagrid, TextField, UrlField, FunctionField, NumberField } from 'react-admin';

const calculateAge = (birthyear) => {
  const currentYear = new Date().getFullYear();
  return currentYear - birthyear;
};

const UserList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="User ID" />
      <TextField source="nickname" label="Name" />
      <TextField source="region" label="Region" />
      <TextField source="gender" label="Gender" />
      <FunctionField
        label="Age"
        render={record => calculateAge(record.birthyear)}
      />
      <TextField source="description" label="Description" />
      <UrlField source="profile_image_url" label="Profile Image" />
      <TextField source="workout_experience" label="Workout Experience" />
      <TextField source="current_location_latitude" label="Latitude" />
      <TextField source="current_location_longitude" label="Longitude" />
      <TextField source="last_login" label="Last Login" />
      <NumberField source="seconds_since_last_login" label="Seconds Since Last Login" />
      <TextField source="created_at" label="Created At" />
      <TextField source="updated_at" label="Updated At" />
      <TextField source="deleted_status" label="Deleted Status" />
      <TextField source="deleted_at" label="Deleted At" />
    </Datagrid>
  </List>
);

export default UserList;
