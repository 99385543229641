// src/components/PushNotificationSend.jsx

import React from 'react';
import { Create, TextInput, Toolbar, SaveButton, SimpleForm, useNotify, useRedirect, required } from 'react-admin';

const PushNotificationSend = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSave = async (data) => {
    try {
      const token = localStorage.getItem('authToken'); // JWT 토큰을 저장한 방법에 따라 다를 수 있습니다.

      // 현재 경로에 따라 엔드포인트 변경
      const envPrefix = window.location.pathname.startsWith('/qa') ? '/qa' : '/prod';

      const response = await fetch(`${envPrefix}/api/send-fcm`, { // /prod/api/send-fcm 또는 /qa/api/send-fcm
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // 인증 헤더 추가
        },
        body: JSON.stringify({
          title: data.title,
          message: data.message,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || '푸시 알림 전송에 실패했습니다.');
      }

      notify('푸시 알림이 성공적으로 전송되었습니다.', 'success');
      redirect('/push-notifications');
    } catch (error) {
      notify(`오류: ${error.message}`, 'error');
    }
  };

  return (
    <Create title="푸시 알림 전송" {...props}>
      <SimpleForm save={handleSave} toolbar={<CustomToolbar />}>
        <TextInput source="title" label="알림 제목" validate={required()} />
        <TextInput source="message" label="알림 메시지" validate={required()} />
      </SimpleForm>
    </Create>
  );
};

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="푸시 전송" />
  </Toolbar>
);

export default PushNotificationSend;
