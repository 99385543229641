// src/components/Dashboard.jsx
import React from 'react';

const Dashboard = () => (
  <div>
    <h1>대시보드</h1>
    <p>안녕하쇼. 나 모티브 대시보드이올시다.</p>
  </div>
);

export default Dashboard;
